import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { POP_SHOW_EFFECT_ON_NGIF_TRIGGER } from '@plano/animations';
import { SchedulingApiShiftModel } from '@plano/client/scheduling/shared/api/scheduling-api-shift-model.service';
import { PFormsService } from '@plano/client/service/p-forms.service';
import { EditableControlInterface } from '@plano/client/shared/p-editable/editable/editable.directive';
import { SectionWhitespace } from '@plano/client/shared/page/section/section.component';
import { RightsService, SchedulingApiBooking, SchedulingApiBookingParticipant, SchedulingApiShiftModelCourseTariff } from '@plano/shared/api';
import { PApiPrimitiveTypes } from '@plano/shared/api/base/generated-types.ag';
import { Config } from '@plano/shared/core/config';
import { enumsObject } from '@plano/shared/core/utils/the-enum-object';
import { AISwitchUIType } from '@plano/shared/p-forms/p-ai-switch/p-ai-switch.component';
import { PShiftmodelTariffService } from '@plano/shared/p-forms/p-shiftmodel-tariff.service';

@Component({
	selector: 'p-tariff-form[formGroup]',
	templateUrl: './p-tariff-form.component.html',
	styleUrls: ['./p-tariff-form.component.scss'],
	changeDetection: ChangeDetectionStrategy.Default,
	animations: [ POP_SHOW_EFFECT_ON_NGIF_TRIGGER ],
})
// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
export class PTariffFormComponent {
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public formGroup ! : FormGroup;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() public tariff ! : SchedulingApiShiftModelCourseTariff;
	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Input() private shiftModel : SchedulingApiShiftModel | null = null;

	/**
	 * If the tariff is applied to a booking, this is the booking.
	 */
	@Input() protected booking : SchedulingApiBooking | null = null;

	/**
	 * If the tariff is applied to a participant, this is the participant.
	 */
	@Input() protected participant : SchedulingApiBookingParticipant | null = null;

	/**
	 * If any value of the tariff gets changed - this gets triggered.
	 */
	@Output() public formValueChange = new EventEmitter<unknown>();

	@Input() public api : EditableControlInterface['api'] = null;

	// eslint-disable-next-line jsdoc/require-jsdoc -- This disable line has been added when we enabled the rule for ExportNamedDeclaration and @Input()/@Output() decorators
	@Output() public dismissFeeBox : EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		private rightsService : RightsService,
		public pShiftmodelTariffService : PShiftmodelTariffService,
		public pFormsService : PFormsService,
	) {
	}

	public Config = Config;
	public enums = enumsObject;
	public AISwitchUIType = AISwitchUIType;
	public PApiPrimitiveTypes = PApiPrimitiveTypes;
	public SectionWhitespace = SectionWhitespace;

	/**
	 * Check if user can edit this tariff
	 */
	public get userCanWrite() : boolean | undefined {
		if (this.shiftModel === null) return false;
		return this.rightsService.userCanWrite(this.shiftModel);
	}
}
