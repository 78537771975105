<form
	*ngIf="!!formGroup && !!tariff"
	[formGroup]="formGroup"
>
	<div class="pb-4">
		<p-ai-switch
			[pEditable]="false"
			label="Tarif-Bezeichnung" i18n-label
			description="Diese Bezeichnung wird euren Kunden beim Buchen über das Homepage-Plugin angezeigt. Sie sollte möglichst kurz sein und keine Information darüber enthalten, für wen der Tarif gilt. Letzteres kann weiter unten angegeben werden." i18n-description
			[group]="formGroup"
			[attributeInfo]="tariff.attributeInfoName"
			placeholder="z.B. »Standard« oder »Ermäßigt«" i18n-placeholder
			(valueChange)="formValueChange.emit()"
		></p-ai-switch>
		<p-ai-switch
			[pEditable]="false"
			label="Für wen gilt dieser Tarif?" i18n-label
			description="Kann dieser Tarif nur von bestimmten Menschen oder für bestimmte Uhrzeiten etc. genutzt werden, sollte das hier angegeben werden. Diese Information wird euren Kunden beim Buchen über das Homepage-Plugin angezeigt. Bitte hier keine Angaben zu den <strong>Zahlungsmodalitäten</strong> machen. Dazu eignen sich die Zahlungsarten weiter unten." i18n-description
			[group]="formGroup"
			[attributeInfo]="tariff.attributeInfoDescription"
			placeholder="z.B. »Nur für Schüler, Studenten, Rentner und Schwerbehinderte«" i18n-placeholder
			[type]="AISwitchUIType.TEXT_EDITOR"
			(valueChange)="formValueChange.emit()"
		></p-ai-switch>
	</div>

	<p-section [whitespace]="SectionWhitespace.NONE" class="pb-4">
	<p-bootstrap-form-group>
	<p-ai-form-array
		*ngVar="tariff.fees as fees"
		label="Gebühren" i18n-label
		[formParent]="formGroup"
		[attributeInfo]="tariff.attributeInfoFees"
		#feesFormArrayRef
		addBtnText="Gebühr hinzufügen" i18n-addBtnText
	>
		<p i18n>Ein Tarif besteht aus einer oder mehreren Gebühren. Du kannst deinen Kunden mehr Transparenz bieten, indem du die einzelnen Bestandteile eines Tarifs (z.B. Kosten für Verpflegung, Betreuung, Leihmaterial etc.) mit einer eigenen Gebühr abbildest, sodass beim Buchungsvorgang nachvollziehbar wird, wie sich der Tarif zusammensetzt.</p>
		<p-ai-form-group
			*ngFor="let fee of fees.iterable(); index as i"
			[style.z-index]="fees.length - i"

			[@popShow]
			[formParent]="feesFormArrayRef.childArray"
			[attributeInfo]="fee.attributeInfoThis"
			#feeFormGroupRef
		>
			<p-editable-box
				[pEditable]="false"
				[api]="api"
				[valid]="!feeFormGroupRef.childGroup.invalid"
				(onDismiss)="dismissFeeBox.emit()"

				[disabled]="!userCanWrite"
			>
				<p-editable-box-header>
					<div class="card-options">
						<div class="d-flex flex-wrap align-items-center justify-content-between pl-3 pr-3 pt-2 pb-2 w-100">
							<h5 class="m-0" i18n>{{ fee.name }}</h5>
							<span>{fee.fee !== null && !!(+fee.fee === 0 || +fee.fee), select, true {{{fee.attributeInfoFee | currency}}} other {–}} / {!!fee.perXParticipants, select, true {{{fee.perXParticipants}}} other {–}}<fa-icon class="ml-1" [icon]="'users'"></fa-icon></span>
						</div>
						<div class="d-flex align-items-stretch">
							<button
								type="button"
								class="btn btn-danger card-option p-2 rounded-top-right nowrap no-overflow-wrap"
								(click)="pShiftmodelTariffService.removeTariffFee(fee, booking, participant)"
								[disabled]="tariff.fees.length <= 1"
							>
								<span class="d-flex"><fa-icon [icon]="enums.PlanoFaIconPool.DELETE"></fa-icon>&nbsp;<ng-container i18n>löschen</ng-container></span>
							</button>
						</div>
					</div>
				</p-editable-box-header>
				<p-editable-box-form>
					<p-grid>
						<div class="col-md-6">
							<p-ai-switch
								[pEditable]="false"
								label="Gebühren-Bezeichnung" i18n-label
								description="Diese Bezeichnung wird euren Kunden beim Buchen über das Homepage-Plugin angezeigt. Sie sollte möglichst kurz sein." i18n-description
								[group]="feeFormGroupRef.childGroup"
								[attributeInfo]="fee.attributeInfoName"
								placeholder="z.B. »Verpflegung« oder »Trainer«" i18n-placeholder
								(valueChange)="formValueChange.emit()"
							></p-ai-switch>
						</div>
						<div class="col-md-6">
							<p-ai-switch
								[pEditable]="false"
								label="Brutto-Preis" i18n-label
								[group]="feeFormGroupRef.childGroup"
								[attributeInfo]="fee.attributeInfoFee"
								placeholder="z.B. 10" i18n-placeholder
								(valueChange)="formValueChange.emit()"
							></p-ai-switch>
						</div>
						<div class="col-md-6">
							<p-ai-switch
								[pEditable]="false"
								label="Pro X Personen" i18n-label
								[group]="feeFormGroupRef.childGroup"
								[attributeInfo]="fee.attributeInfoPerXParticipants"
								placeholder="z.B. »4«" i18n-placeholder
								[inputGroupAppendIcon]="enums.PlanoFaIconPool.ITEMS_PARTICIPANT"
								(valueChange)="formValueChange.emit()"
							></p-ai-switch>
						</div>
						<div class="col-md-6">
							<p-ai-switch
								[pEditable]="false"
								label="MwSt." i18n-label
								[group]="feeFormGroupRef.childGroup"
								[attributeInfo]="fee.attributeInfoVatPercent"
								[type]="AISwitchUIType.DROPDOWN"
								(valueChange)="formValueChange.emit()"
							>
								<p-ai-switch-item
									*ngFor="let possibleVatPercent of fee.api!.data.possibleVatPercents.iterable()"
									[label]="(possibleVatPercent | percent:'1.0-1')!"
									[value]="possibleVatPercent"
								></p-ai-switch-item>
							</p-ai-switch>
						</div>

					</p-grid>

				</p-editable-box-form>
			</p-editable-box>

		</p-ai-form-group>
	</p-ai-form-array>
	</p-bootstrap-form-group>
	</p-section>

	<p-section [whitespace]="SectionWhitespace.NONE" class="pb-4">
		<h4 class="mb-3"><ng-container i18n>Zusätzliche Kunden-Info</ng-container>&nbsp;<p-icon-tooltip i18n>Wählt ein Kunde diesen Tarif, hast du die Möglichkeit, die Person um eine zusätzliche Angabe zu bitten – z.B. um eine Mitgliedsnummer. Dazu wird im Buchungsformular ein Pflicht-Textfeld eingeblendet, dessen Titel du hier bestimmen kannst. Bitte wähle einen kurzen und aussagekräftigen Titel.</p-icon-tooltip></h4>
		<p-grid>
			<div class="col-md-6">
				<p-ai-switch
					label="Abfrage im Buchungsformular" i18n-label
					[group]="formGroup"
					[attributeInfo]="tariff.attributeInfoHasAdditionalField"
					valueText="Pflicht-Textfeld anzeigen" i18n-valueText
					(valueChange)="formValueChange.emit()"
				></p-ai-switch>
			</div>
			<div class="col-md-6">
				<p-ai-switch
					label="Titel des Textfelds" i18n-label
					[group]="formGroup"
					[attributeInfo]="tariff.attributeInfoAdditionalFieldLabel"
					placeholder="z.B. »Mitgliedsnummer«" i18n-placeholder
					[readMode]="false"
					(valueChange)="formValueChange.emit()"
				></p-ai-switch>
			</div>
		</p-grid>
	</p-section>

</form>
